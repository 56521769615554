import BaseHttpService from './BaseHttpService';
import BlainBaseService from './BlainBaseService';
import EventBusService from './EventBusService';
import LoggerService from './LoggerService';
import { FulfillmentType } from '../types/ProductDelivery';
import { IBaseHttpService } from '../types/Http';
import { IEventBusService } from '../types/EventBus';
import { IListableProductJson, IProductPriceViewModel, IWarranty } from '../types/Product';
import { ILoggerService } from '../types/Logger';
import { IPdpSimilarProduct } from 'JS/types/PDP';
import { IProductCarousel, IProductCarouselProduct } from '../types/Carousel';
import { IQuickCartSummaryJson } from '../types/Order';
import { IStoreWithStock } from '../types/Inventory';
import { IUpsellBundle } from 'JS/types/PDP';


class ProductService extends BlainBaseService {

	constructor(
		httpSvc: IBaseHttpService = new BaseHttpService(),
		eventBusService: IEventBusService = new EventBusService(),
		logger: ILoggerService = new LoggerService(),
	) {
		super(eventBusService, logger, httpSvc);
	}

	async getStockStatus(payload: IStockStatusRequest): Promise<IProductEffectiveInventoryResponse> {
		try {
			const response = await this._httpSvc.post<IProductEffectiveInventoryResponse>('/products/a/stockstatus-json', payload);
			if (response) return response;
			return null;
		} catch (err) {
			this.handleError(err);
		}
	}

	async getProductLastFulfillmentType(blainNumber: string): Promise<FulfillmentType> {
		try {
			const response = await this._httpSvc.post<FulfillmentType>('/click-to-reorder/get-fulfillment-type', { blainNumber });
			if (response) return response;
			return null;
		} catch (err) {
			this.handleError(err);
		}
	}

	async addToCart(payload: IPDPAddToCartRequest): Promise<IPDPAddToCartResponse> {
		try {
			const response = await this._httpSvc.post<IPDPAddToCartResponse>('/products/a/addtocart', payload);
			if (response) return response;
			return null;
		} catch (err) {
			this.handleError(err);
		}
	}

	async setWarranty(request: IWarrantyUpdateRequest): Promise<IWarrantyUpdateResponse> {
		try {
			const response = await this._httpSvc.post<IWarrantyUpdateResponse>('/products/a/update-warranty', request);
			return response;
		} catch (err) {
			this.handleError(err);
		}
	}

	async removeWarranty(request: IWarrantyUpdateRequest): Promise<boolean> {
		try {
			const response = await this._httpSvc.post<boolean>('/products/a/remove-warranty', request);
			return response;
		} catch (err) {
			this.handleError(err);
		}
	}

	async getListableProduct(blainNumber: string): Promise<IListableProductJson | null> {
		const url = `/product/a/getproducts?blainNumbers=${blainNumber}`;
		const result = await this._httpSvc.get<IListableProductJson[]>(url);
		return Array.isArray(result) && result.length ? result[0] : null;
	}

	async getListableProducts(blainNumbers: string[]): Promise<IListableProductJson[]> {
		const url = `/product/a/getproducts?blainNumbers=${blainNumbers.join(',')}`;
		return this._httpSvc.get<IListableProductJson[]>(url);
	}

	async getUpsellBundleForProduct(blainNumber: string) {
		const url = `/products/a/getbundleforproduct?blainNumber=${blainNumber}`;
		return this._httpSvc.get<IUpsellBundle>(url);
	}
	async getSimilarProducts(blainNumber: string): Promise<IPdpSimilarProduct[]> {
		const url = `/product/a/getSimilarProducts?blainNumber=${blainNumber}`;
		return this._httpSvc.get<IPdpSimilarProduct[]>(url);
	}

	async addBundleToCart(request: AddBundleToCartRequest): Promise<IPDPAddToCartResponse> {
		return this._httpSvc.post('/products/a/bundletocart', request);
	}

	async addProductComparisonToCart(request: AddProductComparisonToCartRequest): Promise<IPDPAddToCartResponse> {
		return this._httpSvc.post('/products/a/productcomparisontocart', request);
	}
}

export interface IStockStatusRequest {
	blainNumber?: string;
	zip?: string | number;
	filterOnlyInStock?: boolean;
	mustIncludeLocalStore?: boolean;
	exactPosition?: ISimpleGeoCoordinates | null;
}

export interface ISimpleGeoCoordinates {
	latitude: number;
	longitude: number;
}

export interface IPDPAddToCartRequest {
	blainNumber: string;
	qty: number;
	fulfillmentType: FulfillmentType;
	userConfirmed: boolean;
	userAgreed: boolean;
	updateSubscriptionRequest: IUpdateSubscriptionRequest | null;
	skipUpsell: boolean | null;
	ignoreFulfillmentType: boolean | null;
}

export interface IUpdateSubscriptionRequest{
	frequency: number;
}

export interface AddToCartProduct {
	name: string;
	brand: string;
	imageUrl: string;
	imageUrlWebP: string;
	blainNumber: string;
	quantity: number;
	warrantyOptions?: AddToCartWarrantyOptions;
	addToCartPriceModel: IAddToCartPriceJson;
	subscriptionFrequency: string;
}

export interface AddToCartWarrantyOptions {
	warranties: IWarranty[],
	preferredWarranty: IWarranty,
}

export interface IMilwaukeeAddToCartResponse {
	addToCartResponse: IPDPAddToCartResponse;
	productsAddedAsCarousel: IProductCarouselProduct[] | null;
}

export type IPDPAddToCartResponse =
AddToCartSuccessResponse | MaxLinesExceededResponse
| RequireConfirmationResponse | PickupDeliveryErrorResponse
| RequireAgreementResponse | CannotAddResponse;


export interface AddToCartSuccessResponse {
	result: AddToCartResult.Success;
	cartSummary: IQuickCartSummaryJson;
	addedProducts: AddToCartProduct[];
	upsellProducts?: IProductCarousel;
	fulfillmentType: FulfillmentType;
	shipZip: string;
	shippingDeliveryDate?: string;
	orderID: number
}

export interface CannotAddResponse {
	result: AddToCartResult.CannotAdd;
	invalidRequest?: boolean;
}

export interface MaxLinesExceededResponse {
	result: AddToCartResult.MaxLines;
	errorMessage?: string;
}

export interface RequireConfirmationResponse {
	result: AddToCartResult.RequireConfirmation;
	fulfillmentType: FulfillmentType;
	orderFulfillmentOption: FulfillmentType;
	multipleProducts: boolean;
}

export interface RequireAgreementResponse {
	result: AddToCartResult.RequireAgreement;
	agreementText: string;
}

export interface PickupDeliveryErrorResponse {
	result: AddToCartResult.PickupDeliveryError;
	restrictedFulfillmentType: FulfillmentType;
	currentFulfillmentType: FulfillmentType;
	canAddForCurrentFulfillmentType: boolean;
	hasRestrictedItems: boolean;
}

export enum AddToCartResult {
	Success,
	CannotAdd,
	MaxLines,
	PickupDeliveryError,
	RequireConfirmation,
	RequireAgreement
}

export interface IWarrantyUpdateRequest {
	warrantySku: string;
	warrantyQty: number;
	productBlainNumber: string;
}

export interface IWarrantyUpdateResponse {
	warranty: IWarranty;
	success: boolean;
}

export interface AddBundleToCartRequest{
	blainNumbers: string[];
	userConfirmed?: boolean;
}

export interface AddProductComparisonToCartRequest{
	blainNumber: string;
	userConfirmed?: boolean;
}

export interface IProductEffectiveInventoryResponse {
	resolutionType: ProductEffectiveInventoryResolutionType;
	statusList: IStoreWithStock[];
	storeVariant: StoreVariantResponseType;
}

export enum ProductEffectiveInventoryResolutionType {
	Success,
	InvalidZip,
	InvalidCoordinates,
	InvalidBlainNumber,
	NotFound,
	GeoLocationNotFound,
}

export enum StoreVariantResponseType {
	Store,
	Auto,
	Tire,
	NoProduct,
}

export interface IAddToCartPriceJson {
	qty: number;
	eachPrice: string;
	originalTotalPrice: string;
	isFree: boolean;
	isRewardsMemberPrice: boolean;
	isSpecialOfferPrice: boolean;
	lineTotal: number;
	price: IProductPriceViewModel;
}

export default ProductService;